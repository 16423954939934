<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
      <ul v-for="values in errors_back" :key="values">
                    <li v-for="value in values" :key="value">{{value}}</li>
                </ul>
      </div>
    </b-alert>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-row>
          <!-- price -->
          <b-col md="4">
            <b-form-group>
              <label>Price</label>
              <validation-provider
                #default="{ errors }"
                rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                name="Number"
              >
                <b-form-input
                  v-model="itemData.price"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Number Only"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Description -->
          <b-col md="4">
            <b-form-group>
              <label>Description</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="price description"
              >
                <b-form-input
                  v-model="itemData.description"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Date -->
          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="price_date"
              rules="required"
            >
              <b-form-group
                label="Price Date"
                label-for="price_date"
                :state="errors.length > 0 ? false : null"
                placeholder="Purchase Date"
              >
                <flat-pickr v-model="itemData.date" class="form-control" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Currency -->
          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="Currency"
              rules="required"
            >
              <b-form-group
                label="Currency"
                label-for="currency"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="currency"
                  v-model="currencySelect"
                  :state="itemData.currency_id === null ? false : true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="currencyOptions"
                  :selectable="
                    (option) => !option.value.includes('select_value')
                  "
                  label="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
          <!-- submit button -->
          <b-col>
            <b-button
              size="lg"
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>

import flatPickr from "vue-flatpickr-component";
import { required, integer } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";
export default {
  components: {
    flatPickr,
  },

  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      id: 0,
      currencySelect: {
        text: "",
        value: "",
      },
      currencyOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      itemData: {
        date: "",
        price: "",
        description: "",
        currency_id: "",
      },
      required,
      integer,
    };
  },
  created() {
    this.id = this.$route.params.id;
    // get Curencies Index
    axios
      .get("currencies")
      .then((result) => {
        const data = result.data.data;
        for (let curr in data) {
          this.currencyOptions.push({
            value: data[curr].id.toString(),
            text: data[curr].name,
          });
        }
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
    // get price Data
    axios
      .get("items-guide/price/" + this.id + "/show")
      .then((result) => {
        const data = result.data.data;
        this.itemData.date = data.date;
        this.itemData.price = data.price;
        this.itemData.description = data.description;
        this.currencySelect.text = data.currency.name;
        this.currencySelect.value = data.currency.id.toString();
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
  },
  methods: {
    // submit update
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.itemData.currency_id = this.currencySelect.value;
          axios
            .put("items-guide/prices/" + this.id + "/update", this.itemData)
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.back(1);
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
